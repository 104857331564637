import React, { useEffect } from "react";
import { useState } from "react";
import Axios from "axios";
import ImageGift from "../../assets/img/gift.svg";

export default function SubscriptionPayment(props) {
  const [banks, setBanks] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [hasTermsError, setHasTermsError] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBanks();  
  }, []);

  useEffect(() => {
    if (isTermsAccepted) setHasTermsError(false)
  }, [isTermsAccepted]);

  function fetchBanks() {
    Axios.get(`${process.env.REACT_APP_SITE_URL}/v8/data.php?act=kevin_banks`).then((response) => {
      setBanks(response.data.data);
    });
  }

  const handlePlanPayment = () => {
    if (!paymentType) {
      return;
    }

    if (!isTermsAccepted) {
      setHasTermsError(true);
      return;
    }

    window.sys.track(`pricing_${props.plan.key.toLowerCase()}_pay.click`, props.activityId);

    let redirectUrl = process.env.REACT_APP_SITE_URL + `kevin/payment?bank_id=${paymentType}&plan_id=${props.plan.key}`;

    if (props.plan.activity_dropdown) {
      redirectUrl += `&activity_id=${props.activityId}`;
    }

    if (props.plan.discount_code) {
      redirectUrl += `&discount=${props.plan.discount_code.code}`;
    }

    window.location = redirectUrl;
  }

  return (
    <React.Fragment>
      <div className="SubscriptionPayment container mt-5" style={{maxWidth: "860px"}}>
        <h2>Jūsų užsakymas</h2>

        <div className="row card p-3 p-sm-5 mt-5">
          <div className="col-12">
            <div className="row text-left">
              <p>Gerbiami klientai,</p>
              <p>Labai atsiprašome, bet šiuo metu nėra galimybės įsigyti NT ataskaitų. Dėl mūsų buvusio mokėjimų partnerio Kevin.eu nutrauktos veiklos negalime apdoroti mokėjimų. Jūsų susidomėjimą užregistravome ir informuosime jus, kai įdiegsime alternatyvų sprendimą. Jei turite klausimų, prašome rašyti el. paštu <a href="mailto:info@untu.lt">info@untu.lt</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )  
}
